import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
//import imagesStyles from "assets/jss/material-kit-react/imagesStyles.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "components/CustomButtons/Button.jsx";

// @material-ui/icons
import PhoneIcon from '@material-ui/icons/Phone';
import HomeIcon from '@material-ui/icons/Home';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
import WebIcon from '@material-ui/icons/Web';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import domcard from "assets/img/domapotheke/logodomapotheke.png";
import falkencard from "assets/img/falkenapotheke/logofalkenapotheke.png";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

//import { cardTitle } from "assets/jss/material-kit-react.jsx";

/*
const styles = {
  ...imagesStyles,
  cardTitle,
  center: {
    alignSelf: "center"
  },
};
*/

class ProductSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <img style={{height: "100%", width: "100%", display: "block"}} src={domcard} alt="Domapotheke" />
                <CardBody>
                  <HomeIcon />
                  <h4>Domapotheke</h4>
                  <h4>Russelplatz 2</h4>
                  <h4> 45894 Gelsenkirchen</h4>
                  <br/>
                  <p><PhoneIcon/> +49 209 35980701</p>
                  <p ><PrintIcon /> +49 209 35980702</p>
                  <p><EmailIcon /> <a href={'mailto:email@bf-apo.de'}>kontakt@dom-apotheke-buer.de</a></p>
                  <p><WebIcon /> <a href={'https://www.dom-apotheke-buer.de'}>www.dom-apotheke-buer.de</a></p>
                  <br/>
                  <Button color="success" size="lg" href={'https://www.meineapotheke.de/shop/dom-apotheke-gelsenkirchen-45894'}><ShoppingCartIcon/>zum Shop</Button>
                  <Button
                      color="info"
                      size="lg"
                      href="https://www.meineapotheke.de/shop/dom-apotheke-gelsenkirchen-45894/rezept-einloesen"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <ReceiptIcon/>
                    Rezept einlösen
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Card>
                <img style={{height: "100%", width: "100%", display: "block"}}
                      className={classes.imgCardTop} src={falkencard} alt="Falkenapotheke" />
                <CardBody>
                  <HomeIcon />
                  <h4>Buersche Falken-Apotheke</h4>
                  <h4>Goldbergplatz 1 / Hochstr. 1</h4>
                  <h4> 45894 Gelsenkirchen</h4>
                  <br/>
                  <p><PhoneIcon /> +49 209 33727</p>
                  <p><PrintIcon /> +49 209 337699</p>
                  <p><EmailIcon /> <a href={'mailto:email@bf-apo.de'}>email@bf-apo.de</a></p>
                  <p><WebIcon /> <a href={'https://www.buersche-falken-apotheke.de'}>www.buersche-falken-apotheke.de</a></p>
                  <br/>
                  <Button color="success" size="lg" href={'https://www.meineapotheke.de/shop/buersche-falken-apotheke-gelsenkirchen-45894'}><ShoppingCartIcon/>zum Shop</Button>
                  <Button
                      color="info"
                      size="lg"
                      href="https://www.meineapotheke.de/shop/buersche-falken-apotheke-gelsenkirchen-45894/rezept-einloesen"
                      target="_blank"
                      rel="noopener noreferrer"
                  >
                    <ReceiptIcon/>
                    Rezept einlösen
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

export default withStyles(productStyle)(ProductSection);
